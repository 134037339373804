import React from 'react'
import styled from 'styled-components'
import {Link} from 'gatsby'

const TagsWidget = ({data}) => {
  return (
    <Wrapper>
      <Header>Tagged with</Header>
      <Tags>
        {data.slice(1).map(tag => (
          <Tag key={tag.slug}>
            <StyledLink to={`/blog/category/${tag.slug}/`}>
              # {tag.name}
            </StyledLink>
          </Tag>
        ))}
      </Tags>
    </Wrapper>
  )
}

export default TagsWidget

const Wrapper = styled.section`
  padding: 40px 0 0;
`

const Header = styled.h3`
  font-size: 1.6rem;
  color: ${props => props.theme.color.text.lightest};
  letter-spacing: normal;
  margin: 0 0 8px;
`

const Tags = styled.ul`
  display: flex;
  list-style: none;
  flex-wrap: wrap;
`

const Tag = styled.li`
  margin: 0 8px 0 0;
`

const StyledLink = styled(Link)`
  font-size: 1.8rem;
  color: ${p => p.theme.color.secondary.base};
`
