import React from 'react'
import styled from 'styled-components'
import {GatsbyImage, getImage} from 'gatsby-plugin-image'
import PropTypes from 'prop-types'

import Twitter from '../../images/inline-svgs/twitter.svg'
import Globe from '../../images/inline-svgs/globe.svg'

import {Padding} from '../../elements'
import theme from '../../styles/theme'

const AuthorWidget = ({data}) => {
  const twitterUrl =
    data.twitter && `https://twitter.com/${data.twitter.replace(/^@/, ``)}`

  return (
    <Padding bottom small>
      <Wrapper>
        <GatsbyImage
          image={getImage(data.profileImageSharp)}
          alt={`${data.name}'s picture`}
          style={{
            borderRadius: '100%',
            marginBottom: '10px',
          }}
        />
        <Content>
          <Name>{data.name}</Name>
          {data.bio && <Bio>{data.bio}</Bio>}
          <Links>
            {data.twitter && (
              <a
                href={twitterUrl}
                title="Twitter"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Twitter fill={theme.color.secondary.base} />
              </a>
            )}
            {data.website && (
              <a
                href={data.website}
                title="Website"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Globe color={theme.color.secondary.base} />
              </a>
            )}
          </Links>
        </Content>
      </Wrapper>
    </Padding>
  )
}

export default AuthorWidget

AuthorWidget.propTypes = {
  data: PropTypes.shape({
    slug: PropTypes.string,
    name: PropTypes.string.isRequired,
    bio: PropTypes.string,
    website: PropTypes.string,
    twitter: PropTypes.string,
    profile_image: PropTypes.string,
    profileImageSharp: PropTypes.object,
  }),
}

const Wrapper = styled.section`
  @media (min-width: ${theme.breakpoints.md}) {
    display: grid;
    align-items: start;
    grid-template-columns: 80px auto;
    gap: 20px;
  }
`

const Content = styled.div``

const Name = styled.h3`
  font-size: 1.8rem;
`

const Bio = styled.p`
  font-size: 1.6rem;
  color: ${props => props.theme.color.text.lightest};
  margin: 6px 0 0;
`

const Links = styled.div`
  display: flex;
  align-items: center;
  margin: 12px 0 0;

  a {
    width: 23px;
    height: 23px;
    margin: 0 4px 0 0;
    display: block;
  }

  svg {
    width: 100%;
    height: 100%;
  }
`
