import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import {BoxedContainer, Padding, ReverseArrowLink} from '../../elements'

const BlogHeader = ({title, publishedAt, publishedAtFormatted}) => {
  return (
    <Wrapper>
      <BoxedContainer>
        <Padding topBottom>
          <ReverseArrowLink to="/blog/">Blog</ReverseArrowLink>
          <Title>{title}</Title>
          <Time dateTime={publishedAt}>
            Published on {publishedAtFormatted}
          </Time>
        </Padding>
      </BoxedContainer>
    </Wrapper>
  )
}

export default BlogHeader

BlogHeader.propTypes = {
  title: PropTypes.string,
  publishedAt: PropTypes.string,
  publishedAtFormatted: PropTypes.string,
}

const Wrapper = styled.header`
  background: ${props => props.theme.color.grey.lighter};
`

const Title = styled.h1`
  margin: 0.3em 0 0.45em;
  max-width: 750px;
`

const Time = styled.time`
  color: ${props => props.theme.color.text.lightest};
  font-size: 1.6rem;

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    font-size: 1.8rem;
  }
`
