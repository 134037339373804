import React from 'react'
import {graphql} from 'gatsby'

import {Layout, MetaData, RenderContent} from '../components'
import {BlogHeader, AuthorWidget, TagsWidget} from '../components/blog'
import {BoxedContainer, Padding} from '../elements'

const Post = ({data}) => {
  const post = data.ghostPost
  const htmlAst = post.childHtmlRehype && post.childHtmlRehype.htmlAst
  const transformedHtml = post.childHtmlRehype && post.childHtmlRehype.html

  return (
    <Layout>
      <MetaData data={post} />
      <article>
        <BlogHeader
          title={post.title}
          publishedAt={post.published_at_machine_readable}
          publishedAtFormatted={post.published_at_pretty}
        />

        <Padding topBottom>
          <RenderContent
            htmlAst={htmlAst}
            html={transformedHtml || post.html}
          />
        </Padding>

        {/* <aside>
          <AuthorWidget data={post.primary_author} />
          <hr />
          {post.tags.length > 1 && <TagsWidget data={post.tags} />}
        </aside> */}
      </article>
    </Layout>
  )
}

export default Post

export const query = graphql`
  query ($slug: String!) {
    ghostPost(slug: {eq: $slug}) {
      ...GhostPostFields
    }
  }
`
